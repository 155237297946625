// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-hardware-js": () => import("./../../../src/pages/hardware.js" /* webpackChunkName: "component---src-pages-hardware-js" */),
  "component---src-pages-help-js": () => import("./../../../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-internet-js": () => import("./../../../src/pages/internet.js" /* webpackChunkName: "component---src-pages-internet-js" */),
  "component---src-pages-print-js": () => import("./../../../src/pages/print.js" /* webpackChunkName: "component---src-pages-print-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-service-js": () => import("./../../../src/pages/service.js" /* webpackChunkName: "component---src-pages-service-js" */),
  "component---src-pages-settings-js": () => import("./../../../src/pages/settings.js" /* webpackChunkName: "component---src-pages-settings-js" */),
  "component---src-pages-terms-en-js": () => import("./../../../src/pages/terms_en.js" /* webpackChunkName: "component---src-pages-terms-en-js" */),
  "component---src-pages-terms-js": () => import("./../../../src/pages/terms.js" /* webpackChunkName: "component---src-pages-terms-js" */),
  "component---src-pages-terms-zh-js": () => import("./../../../src/pages/terms_zh.js" /* webpackChunkName: "component---src-pages-terms-zh-js" */)
}

